import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../layouts/Layout';
import PostCards from '../components/PostCards';
import Seo from '../components/Seo';
import { rendererOptions } from '../utils';

const Post = ({ data }) => {
  const { post } = data;

  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.excerpt.internal.content}
        lang={post.locale}
        imageUrl={post.featuredImage?.file?.url}
        path={`/blog/${post.slug}/`}
      />

      <div className="px-4 sm:px-6 lg:px-16 mx-auto max-w-5xl prose post">
        <article>
          {post.metadata.tags && (
            <div className="mb-8 text-center uppercase">
              {post.metadata.tags
                .filter((tag) =>
                  ['updates', 'case study'].includes(tag.contentful_id)
                )
                .map((tag) => (
                  <a
                    key={tag.contentful_id}
                    href={`/blog/tags/${tag.contentful_id}`}
                    className="py-2 px-4 mr-2 text-xs text-white no-underline bg-oxford-blue rounded-full"
                  >
                    {tag.name}
                  </a>
                ))}
            </div>
          )}
          <h1 className="text-center">{post.title}</h1>
          <h2 className="mt-0 mb-8 text-xl font-normal text-center text-gray-500">
            {post.excerpt.internal.content}
          </h2>
          <div className="text-sm text-center">
            Published {post.publishDate} &middot; By {post.author}
          </div>

          <hr className="mx-auto mt-8 w-1/2" />

          {documentToReactComponents(
            JSON.parse(post.body.raw),
            rendererOptions({ locale: post.locale }, post.body.references)
          )}
        </article>
        {post.related && (
          <>
            {post.relatedHeading ? (
              <h2>{post.relatedHeading}</h2>
            ) : (
              <>
                <hr />
                <h2>Related</h2>
              </>
            )}
            <PostCards posts={post.related} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query Post($id: String) {
    post: contentfulBlog(id: { eq: $id }) {
      title
      slug
      author
      publishDate(formatString: "MMMM DD, YYYY")
      excerpt {
        internal {
          content
        }
      }
      featuredImage {
        title
        file {
          url
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            file {
              url
            }
          }
        }
      }
      relatedHeading
      related {
        ... on ContentfulKbAppArticle {
          id
          category: kbAppCategory {
            slug
          }
          featuredImage {
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
          }
          title
          slug
          metaDescription
        }
      }
      metadata {
        tags {
          contentful_id
          name
        }
      }
      locale: node_locale
    }
  }
`;
